//subscriptionProducts.directive.js
(function() {

    'use strict';

    angular
        .module('app.directives')
        .directive('subscriptionProducts', subscriptionProducts);

    subscriptionProducts.$inject = [
        'TABLECONFIG',
        'InstrumentService',
        'MediaQueryService'
    ];

    function subscriptionProducts(
        TABLECONFIG,
        InstrumentService,
        MediaQueryService
    ) {
        var directive = {
            controller: controller,
            templateUrl: '/templates/subscriptionProducts.html',
            restrict: 'E',
            scope: {
                name: '@?',
                data: '='
            }
        };

        function controller($scope) {
            var vm = $scope;
            vm.tableTpl = TABLECONFIG.cms.subscriptionProducts;
            vm.component = vm.name.replaceAll(" ", "");
            vm.instrumentData = {
                list: vm.data
            };
            vm.media = MediaQueryService.getMedia();

            // Get instrument documents
            vm.getDocuments = function(event, instrument) {
                event.stopPropagation();

                var container = event.target;

                //Set flyout top position
                function setPosition() {
                    $('flyout-documents').css('top', $(event.target).position().top + 40);
                    vm.showFlyOutDocuments = 'downloads';
                    vm.flyOutDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link
                }

                // Select flyout or modal regarding the current mediaquery
                function showFlyout() {

                    // Set button active state
                    $(container).addClass('active');

                    setPosition();
                }

                //Show flyout if document data is already added
                if (instrument.documents) {
                    showFlyout();
                    return;
                }

                // Get data using service
                $(container).addClass('loading');
                InstrumentService.getDocuments(instrument.sin.value).then(function(data) {
                    vm.flyoutInstrument = data;
                }).finally(function() {
                    if (!instrument.isKidAvailable.value) {
                        vm.flyoutInstrument.kid = null;
                    }

                    showFlyout();
                    $(container).removeClass('loading');
                });
            };
        }

        return directive;
    }
})();