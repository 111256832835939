var lang_fr_CH = {
    "1D": "1J",
    "1M": "1M",
    "1Y": "1A",
    "3M": "3M",
    "6M": "6M",
    "30Y": "MAX",
    "abort": "Abandonner",
    "accept_legal_information": "J'accepte les mentions légales pour les Produits Structurés.",
    "accept": "Accepter",
    "access_restriction": "Restriction d'accès",
    "accruedInterestPriCurPercent": "Intérêts courus",
    "active_filter": "Filtres actifs",
    "adjust": "Adjust",
    "filter_count_mobile": "Adapter le filtre",
    "advanced_filters": "Filtres avancés",
    "all": "Tout / toute",
    "annually": "Annuel",
    "apply": "Appliquer",
    "ask_criteria": "Achat",
    "ask": "Achat",
    "mainmenu": "Menu principal",
    "at_expiry": "A maturité",
    "Autocallable_SoftCallable_MultiBarrierReverseConvertible": "Autocallable / Softcallable <br/>Multi Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertible": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibles": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Barrier Reverse Convertible avec Coupon Conditionnel",
    "AutocallableBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Barrier Reverse Convertible avec Coupon Conditionnel",
    "AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableMultiBarrierReverseConvertible": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibles": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableMultiReverseConvertible": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibles": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel",
    "AutocallableMultiReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel",
    "AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableMultiReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableReverseConvertible": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibles": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibleWithConditionalCoupon": "Autocallable Reverse Convertible avec Coupon Conditionnel",
    "AutocallableReverseConvertibleWithConditionalCoupons": "Autocallable Reverse Convertible avec Coupon Conditionnel",
    "AutocallableReverseConvertiblesWithConditionalCoupon": "Autocallable Reverse Convertible avec Coupon Conditionnel",
    "AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Reverse Convertible avec Coupon Conditionnel Mémoire",
    "back": "En arrière",
    "barrier_too_close_multi_notification": "L'amortisseur de risque est presque épuisé (distance à la barrière du sous-jacent le plus faible < 5.00%). ",
    "show_alternative_products": "Montrer des produits alternatifs",
    "barrier_too_close_single_notification": "L'amortisseur de risque est presque épuisé (distance à la barrière < 5.00%).",
    "barrier": "Barrière",
    "barrierHitProbability2MaturityPercent": "Probabilité d'une barrière touchée",
    "barrierLevelAbsoluteReferenceCurrency": "Barrière",
    "barrierLevelRelativePercent": "Barrière",
    "barrierObservationMethod": "Type de barrière",
    "BarrierReverseConvertible": "Barrier Reverse Convertible",
    "BarrierReverseConvertibles": "Barrier Reverse Convertible",
    "barrierTouched_criteria": "Barrière touchée",
    "barrierTouched": "Barrière touchée",
    "betaVersion": "Version Beta",
    "betaVersionText": "Version Beta avec des fonctionalités limitées",
    "bi-monthly": "Chaque 2 mois",
    "bi-weekly": "Bihebdomadaire",
    "bid": "Vente",
    "BonusCertificate": "Certificat Bonus",
    "TrackerCertificates": "Certificat Tracker",
    "TrackerCertificate": "Certificat Tracker (statique)",
    "TrackerCertificateName": "LUKB Certificat Tracker",
    "TrackerCertificateCategory": "Catégorie SSPA: 1300 - Participation",
    "TrackerCertificateOpenEnd": "Certificat Tracker Open End (statique)",
    "TrackerCertificateOpenEndName": "LUKB Certificat Tracker",
    "TrackerCertificateOpenEndCategory": "Catégorie SSPA: 1300 - Participation",
    "TemporaryProduct": "Temporary Product",
    "browser_title_product_detail": "Page de produit détaillée",
    "call": "Appeler",
    "cancel": "Annuler",
    "CapitalProtectionCertificatesWithParticipation": "Certificat de Protection du Capital avec Participation",
    "CapitalProtectionCertificateWithParticipation": "Certificat de Protection du Capital avec Participation",
    "CapitalProtectionCertificateWithParticipationWorstOf": "Certificat de Protection du Capital avec Participation sur le worst of",
    "CapitalProtectionCertificatesWithParticipationWorstOf": "Certificat de Protection du Capital avec Participation sur le worst of",
    "CapitalProtectionProduct": "Certificat de Protection du Capital",
    "CapitalProtectionProducts": "Certificat de Protection du Capital",
    "CapitalProtections": "Protection du capital",
    "CapitalProtection": "Protection du capital",
    "CapitalProtectionWithParticipation": "Note à Capital Protégé avec Participation",
    "CapitalProtectionWithParticipationWorstOf": "Note à Capital Protégé avec Participation sur le worst of",
    "CapitalProtectionWithParticipationCapped": "Note à Capital Protégé avec Participation plafonnée",
    "CapitalProtectionWithParticipationSumBasket": "Note à Capital Protégé avec Participation sur le Basket",
    "CapitalProtectionWithParticipationSumBasket_SPPNameGeneration": "Note à Capital Protégé avec Participation sur",
    "CapitalProtectionWithParticipationCappedSumBasket": "Note à Capital Protégé avec Participation plafonnée sur le Basket",
    "CapitalProtectionWithParticipationCappedSumBasket_SPPNameGeneration": "Note à Capital Protégé avec Participation plafonnée sur",
    "CapitalProtectionWithConditionalCouponWorstOf": "Note à Capital Protégé avec Coupon Conditionnel",
    "CapitalProtectionWithConditionalCouponWorstOf_SPPNameGeneration": "Note à Capital Protégé avec %s de Coupon Conditionnel",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf_SPPNameGeneration": "Note à Capital Protégé avec %s de Coupon Conditionnel Mémoire",
    "cash_settlement": "Paiement en espèce",
    "cash": "Paiement en espèce",
    "cashorphysical": "Espèces ou livraison physique",
    "categorization": "Catégorisation",
    "change_criteria": "Changer les critères",
    "session_close": "Clôture quotidienne",
    "chart_disclaimer_BarrierReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a. et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_DiscountCertificate": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a. et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a. et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_MultiBarrierReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a. et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a. et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a. et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableBarrierReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a., le yield to call et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_SoftCallableBarrierReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a., le yield to call et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a., le yield to call et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_SoftCallableMultiBarrierReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le rendement max. p.a., le yield to call et la probabilité d'une barrière touchée sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_ReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableReverseConvertibleWithConditionalMemoryCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableReverseConvertiblesWithConditionalCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_MultiReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalCoupon": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le yield to call et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_SoftCallableReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le yield to call et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_AutocallableMultiReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le yield to call et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_SoftCallableMultiReverseConvertible": "La performance du produit, le rendement max. jusqu'à l'échéance, le yield to call et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons payés depuis l'émission.",
    "chart_disclaimer_CapitalProtectionWithParticipation": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent, la distance au prix d'exercice et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.",
    "chart_disclaimer_CapitalProtectionWithParticipationCapped": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent, la distance au prix d'exercice et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedSumBasket": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent, la distance au prix d'exercice et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.",
    "chart_disclaimer_CapitalProtectionWithParticipationWorstOf": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant, la distance au prix d'exercice et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedWorstOf": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant, la distance au prix d'exercice et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.",
    "chart_disclaimer_TrackerCertificate": "La performance du produit est basés sur les cours actuels. Le cours des sous-jacents sont basés sur le cours de clôture du jour précédent.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons et paiements de la compensation payés depuis l'émission.",
    "chart_disclaimer_ActivelyManagedTrackerCertificate": "La performance du produit est basés sur les cours actuels.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons et paiements de la compensation payés depuis l'émission.",
    "chart_disclaimer_TrackerCertificateOpenEnd": "La performance du produit est basés sur les cours actuels.<br/>L'indicateur-clé «Performance du produit depuis l'émission» prend en compte à la fois l'évolution du prix du produit et les coupons et paiements de la compensation payés depuis l'émission.",
    "chart_disclaimer_CapitalProtectionWithParticipationSumBasket": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent, la distance au prix d'exercice et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent.",
    "chart_disclaimer_CapitalProtectionWithConditionalCouponWorstOf": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant, la distance au prix d'exercice et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.",
    "chart_disclaimer_CapitalProtectionWithConditionalMemoryCouponWorstOf": "La performance du produit est basée sur les cours actuels.<br/>La performance du sous-jacent le moins performant, le sous-jacent le moins performant, la distance au prix d'exercice et les cours des sous-jacents sont basés sur les cours de clôture du jour précédent.",
    "conditionalCouponPercentPa": "Coupon p.a.",
    "confirm_residences": "Je confirme être domicilié/établi en Suisse.",
    "contact_person": "Personnes de contact",
    "contact": "Contact",
    "continuous": "continue",
    "couponInterest": "Coupon",
    "couponPaymentDate": "Date de paiement",
    "couponPaymentDateAndInterest": "Dates de paiement du coupon",
    "couponPaymentFrequency": "Paiement du coupon",
    "couponPercentPa": "Coupon p.a.",
    "couponPercentPa_criteria": "Coupon p.a. %",
    "coupons": "Niveau de coupon",
    "create_pdf_from_site": "Générer un PDF de la page",
    "criteria": "Critères",
    "currency": "Devise",
    "daily_close_prices": "Clôture journalière",
    "daily": "quotidien",
    "days2MaturityDate": "Durée résiduelle en jours<br />jusqu'à la date de fixing final",
    "disclaimer_footer": "Si vous êtes domicilié/établi dans un autre pays, vous n'avez pas le droit d'accéder aux informations relatives aux produits structurés émis par la LUKB. Nous vous prions dans ce cas d'interrompre le processus.",
    "disclaimer_text": "Pour pouvoir accéder aux informations sur les produits, vous devez remplir les conditions suivantes:",
    "DiscountCertificate": "Certificat Discount",
    "display_all_underlyings": "Afficher toutes les sous-jacents",
    "display_all": "Afficher tous",
    "display_all_products": "Afficher tous les produits",
    "distance2BarrierLevelPercent": "Distance barrière",
    "distance2BarrierLevelPercent_criteria": "Distance barrière %",
    "distance2ClosestBarrierLevelPercent": "Distance barrière",
    "distance2StrikePercent": "Distance Prix d'exercice",
    "distance2StrikePercent_criteria": "Distance Prix d'exercice %",
    "distance2triggerLevelRefCurrencyPercent": "Distance seuil de remboursement anticipé",
    "distance2triggerLevelRefCurrencyPercent_criteria": "Distance seuil de remboursement anticipé %",
    "distanceRange2BarrierLevel": "Distance barrière",
    "download": "Download",
    "downloads": "Downloads",
    "dr": "Dr.",
    "early_redemption": "Remboursement anticipé",
    "earlyRedemptionList": "Remboursement anticipé",
    "email_address": "Adresse e-mail",
    "error_email": "Prière de saisir une adresse e-mail valide.",
    "error_full_name": "Prière de saisir votre prénom et votre nom.",
    "error_message": "Saisir votre message",
    "error_phone": "Prière de saisir un numéro de téléphone.",
    "error_salutation": "Prière de sélectionner un titre.",
    "every": "chaque",
    "factsheet_dl": "Télécharger la Factsheet",
    "factsheet": "Factsheet",
    "false": "Non",
    "filter": "Filter",
    "filter_barrierObservationMethod": "Type de barrière",
    "filter_currency": "Devise",
    "filter_distanceRange2BarrierLevel": "Amortisseur de risque",
    "filter_group": "Groupe de produit / Type de produit",
    "filter_maturityDate": "Echéance",
    "filter_products": "Filtrer les produits",
    "filter_underlying": "Sous-jacent",
    "first_last_name": "Prénom et nom",
    "found_products": "Produits trouvés",
    "glossary_disclaimer": "*Pour les produits ayant plusieurs sous-jacents, il s'agit du sous-jacent avec la performance la plus faible",
    "glossary": "Glossaire",
    "group_names": "Type de produit",
    "groupName": "Type de produit",
    "hasTouchedBarrier": "Evénement de barrière",
    "header_structured_products": "Produits Structurés",
    "hide_all_underlyings": "Masquer tous les sous-jacents",
    "id_ex_swx_exists_criteria": "EX SWX ID existe",
    "id_ex_swx_exists": "EX SWX ID existe",
    "in_subscription_notification": "Ce produit est en souscription jusqu'au {{subscriptionPeriodEndDate}} à {{subscriptionPeriodEndDateTime}}.",
    "inListing_criteria": "Produits listés",
    "inListing": "Produits cotés",
    "isin": "ISIN",
    "isQuanto": "Quanto",
    "issued_products": "Produits émis",
    "issuePrice": "Prix d'émission",
    "issuer": "Emetteur",
    "issuerCallNoticePeriodEndDate": "Date d'observation",
    "key_figures": "Indicateurs-clés",
    "lang_DE": "Allemand",
    "lang_EN": "Anglais",
    "lang_FR": "Français",
    "lang_IT": "Italien",
    "lastTradingDate": "Dernier jour de négociation",
    "less_then_one_month": "< 1 mois",
    "lifecycle": "Cycle de vie",
    "light_footer_title": "Nous sommes là pour vous",
    "listing": "Cotation",
    "market_data_source": "Les données de marché sont mises à disposition par Morningstar et <a href='http://www.solvians.com' title='Solvians' target='_blank'>Solvians</a>.",
    "maturityDate": "Echéance",
    "max_performance_too_low_notification": "Ce produit s'approche du rendement maximal (<1.50%).",
    "max": "Max.",
    "MAX": "Max.",
    "maxPerformancePercentPaPriceCurrency": "Rendement max. p.a.",
    "maxPerformancePercentPaPriceCurrency_criteria": "Rendement max. p.a. %",
    "maxPerformancePercentPaStrikeCurrency": "Coupon Conditionnel Mémoire p.a.",
    "maxPerformancePercentStrikeCurrency_criteria": "Rendement max. jusqu'à l'échéance %",
    "maxPerformancePercentStrikeCurrency": "Rendement max. jusqu'à l'échéance",
    "maxPerformancePercentPriceCurrency": "Rendement max. jusqu'à l'échéance",
    "distance2CapPercentStrikeCurrency_criteria": "Distance cap %",
    "distance2CapPercentStrikeCurrency": "Distance cap",
    "distance2CapAbsoluteStrikeCurrency_level": "niveau de cap",
    "relativeCapStrCur2ReferencePrice_criteria": "Niveau de cap en %",
    "relativeCapStrCur2ReferencePrice": "Niveau de cap",
    "reference_price_absolute": "Initial Fixing",
    "discount": "Rabais",
    "strikeLevelAndDiscount": "Cap et Rabais",
    "metaGroup": "Groupe de produit",
    "metaGroupName": "Groupe de produit",
    "min": "Min.",
    "month": "mois",
    "monthly": "mensuel",
    "months_dat": "mois",
    "months": "mois",
    "more_then_2_years": "> 2 ans",
    "mr": "Monsieur",
    "ms": "Madame",
    "MultiBarrierReverseConvertible": "Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibles": "Multi Barrier Reverse Convertible",
    "MultiReverseConvertible": "Multi Reverse Convertible",
    "MultiReverseConvertibles": "Multi Reverse Convertible",
    "Max_Yeild_At_Expiry": "Rendement max. jusqu'à l'échéance",
    "name_term": "Nom, définition",
    "no_document_available": "Pas de document disponible",
    "no_results": "Aucun résultat",
    "no_search_results": "Aucun résultat de recherche",
    "no": "Non",
    "notification_link": "Vous trouverez ici une sélection de produits",
    "notionalValue": "Nominal",
    "observationDate": "Date d'observation",
    "observationDateTrim": "Date d'observation",
    "on": "sur",
    "one_to_three_months": "1 - 3 mois",
    "one_to_two_years": "1 - 2 ans",
    "onlyQuotedProducts": "Montrer que les produits cotés",
    "open_end": "Open-end",
    "participationRatePercent": "Participation",
    "Participations": "Participation",
    "pdf_create": "Rapport du produit",
    "performance_since_issue_percent": "Performance depuis l'émission",
    "performance": "Performance",
    "performance2NextObservationDatePriceCurrency_criteria": "Yield to Call %",
    "performance2NextObservationDatePriceCurrency": "Yield to Call",
    "performanceSinceIssuePercent": "Performance du produit depuis l'émission",
    "distance2StrikePercentForWorstPerformanceUnderlying": "Distance au Prix d'exercice du sous-jacent le moins performant",
    "phone_by_day": "Numéro de téléphone (durant la journée)",
    "phone": "Téléphone",
    "physical_settlement": "Livraison physique d'ETF",
    "physical": "Livraison physique",
    "price": "Cours",
    "product_barrier_observed_on_expiry": "Observation de la barrière à maturité",
    "product_cash_settlement": "Paiement en espèce",
    "product_category": "Catégorie de produit",
    "capitalProtectionAndStrike": "Niveau de protection du capital et Prix d'exercice",
    "participationAndCap": "Participation et Cap",
    "barrierAndStrike": "Barrière et Prix d'exercice",
    "product_detail_fixingDate": "Date de fixing initial",
    "product_detail_issueDate": "Date d'émission",
    "product_detail_maturityDate": "Date de fixing final",
    "product_detail_redemptionDate": "Date de remboursement",
    "product_detail_subscriptionPeriodEndDate": "Date de clôture de souscription",
    "product_early_redemption_option": "Possibilité de remboursement anticipé",
    "product_group_product_type": "Groupe de produit / Type de produit",
    "product_overview_performance_key_indicators": "Aperçu des produits, performance et indicateurs-clés",
    "product_overview": "Aperçu du produit",
    "product_physical_delivery": "Livraison physique d'ETF",
    "product_quanto": "Le produit est couvert contre le risque de change",
    "product_type_criteria": "Groupe de produit / Type de produit",
    "product_type_search_placeholder": "Groupe de produit / Type de produit…",
    "product_type": "Type de produit",
    "product_underlying_search_placeholder": "Nom, définition…",
    "product": "Produit",
    "products_documents": "Afficher les détails du produit",
    "products_in_subscription_of_lukb": "Produits de Luzerner Kantonalbank en souscription",
    "products_in_subscription": "Produits en<br/>souscription",
    "products_range_0": "3 mois",
    "products_range_1": "6 mois",
    "products_range_2": "9 mois",
    "products_range_3": "1 an",
    "products_range_4": "18 mois",
    "products_range_5": "> 18 mois",
    "products": "Produits",
    "protectionLevelRelativePercent": "Niveau de protection du capital",
    "quanto": "Quanto",
    "quarterly": "trimestriel",
    "quotingMethod": "Type de cotation",
    "clean": "Clean",
    "dirty": "Dirty",
    "realPriceCurrency": "Devise",
    "redemptionDate": "Echéance",
    "relativeStrike2ReferencePrice": "Prix d'exercice",
    "repayment": "Possibilité de remboursement anticipé",
    "repaymentLevelPriCurPercent": "Prochaine date d'observation d'autocall",
    "repaymentLevelPriCurPercentSoftCallable": "Prochaine date d'observation",
    "repaymentValue": "Remboursement",
    "reset_all_filter": "Effacer tous les filtres",
    "ReverseConvertible": "Reverse Convertible",
    "ReverseConvertibles": "Reverse Convertible",
    "salutation": "Titre",
    "search_groups_on": "sur",
    "search_placeholder": "Valeur / ISIN / Sous-jacent / Type de produit",
    "search": "Recherche de produit",
    "select_language": "Sélectionner une langue",
    "semi-annually": "semi-annuel",
    "send_copy_to_email": "Envoyer une copie à mon adresse e-mail",
    "send_message": "Envoyer le message",
    "settlementMethod": "Type de paiement",
    "show_documents (not used anymore)": "Afficher les documents",
    "show_product_detail_page": "Afficher les détails du produit",
    "show_product": "Montrer le produit",
    "show_products": "Montrer les produits",
    "shown_for_7_days": "Ne plus afficher la présente clause de non-responsabilité pendant 7 jours.",
    "six_symbol": "Symbole",
    "six_to_twelve_months": "6 - 12 mois",
    "SoftCallableBarrierReverseConvertible": "Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibles": "Softcallable Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertible": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibles": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiReverseConvertible": "Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibles": "Softcallable Multi Reverse Convertible",
    "SoftCallableReverseConvertible": "Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibles": "Softcallable Reverse Convertible",
    "starting_after": "la première fois après",
    "static_data": "Caractéristiques principales",
    "strike": "Prix d'exercice",
    "structured_products_lukb": "Produits structurés de la Luzerner Kantonalbank",
    "subscriptionPeriodEndDate": "Clôture de la souscription",
    "subscriptionPeriodEndDateMobile": "Clôture de la<br/>souscription",
    "tab_find_products": "Trouver un<br/>produit",
    "tab_opportunities_in_the_secondary_market": "Opportunités en<br/>marché secondaire",
    "tab_opportunities_secondary_market_header": "Opportunités au marché secondaire: <br />Vous trouvez sur cette page une sélection de Multi Barrier Reverse Convertibles - dont certains peuvent offrir un remboursement par anticipation. La sélection est basée sur des critères prédéfinis (Prix, niveau de barrière, yield to call). Tous les produits peuvent être directement négociés via la bourse.",
    "tab_products_in_subscription": "Produits<br/>en souscription",
    "tab_recently_issued": "Récemment<br/>émis",
    "tab_yield_matrix_title": "La matrice de rendement est un instrument de travail unique offrant une véritable valeur ajoutée dans la recherche des produits les plus attractifs du marché secondaire, de type Barrier Reverse Convertibles, Multi Barrier Reverse Convertibles ainsi que Autocallable/Softcallable Barrier Reverse Convertibles. Combinez les sous-jacents, le coussin de risque et la durée afin de trouver le produit optimal qui correspond à votre profil d'investissement. En cliquant ci-dessous sur les rendements selon la durée, vous arriverez directement sur le produit correspondant.",
    "tab_yield_matrix": "Matrice de<br/>rendement",
    "termsheet_dl": "Télécharger la Termsheet",
    "final_terms_dl": "Télécharger Final Terms",
    "termsheet": "Termsheet",
    "final_terms": "Final Terms",
    "third_level_filter_legend": "Les valeurs minimales et maximales proposées se réfèrent aux produits concernés par les filtres sélectionnés",
    "three_to_six_months": "3 - 6 mois",
    "top_of_page": "Haut de page",
    "triggerLevel": "Seuil de remboursement anticipé",
    "triggerLevelRefCurAbsolute": "Seuil de remboursement anticipé",
    "true": "Oui",
    "underlying": "Sous-jacent",
    "underlyingNames": "Sous-jacents",
    "valor": "Valeur",
    "weekly": "hebdomadaire",
    "worstPerformanceUnderlying": "Sous-jacent le moins performant",
    "underlyingPerformance2ReferencePriceRelative": "Evolution du cours du sous-jacent depuis l'émission",
    "worstUnderlyingPerformance2ReferencePriceRelative": "Evolution du cours du moins bon sous-jacent depuis l'émission",
    "write_message_desktop": "Envoyer un message",
    "write_message_mobile": "Envoyer un message",
    "write_message_tooltip": "Envoyer un message",
    "write_us": "Ecrivez-nous",
    "year": "année",
    "years_dat": "années",
    "years": "années",
    "yes": "Oui",
    "YieldEnhancements": "Optimisation de la performance",
    "YieldEnhancement": "Optimisation de la performance",
    "your_message": "Votre message",
    "YTD": "YTD",
    "barrier_observation_on_maturity": "Observation à maturité",
    "contact_success_message": "<b>Merci pour votre message</b><br />Nous allons répondre à votre requête dans les meilleurs délais.<br/>Votre Luzerner Kantonalbank",
    "contact_error_message": "Une erreur est survenue. Veuillez réessayer plus tard.",
    "lang_prospectus": "Prospekt",
    "prospectus": "Prospekt",
    "chart_error": "Pas de données disponibles",
    "corporate_action_executed": "Une Corporate Action a eu lieu. Plus de détails dans les <a href='/services/notifications'>Notifications</a>.",
    "maturity_notification": "Ce produit est échu.",
    "check_products_in_subscription": "Montrer les produits en souscription",
    "early_redeemed_notification": "Ce produit a été rappelé pour un remboursement par anticipation.",
    "customDateTime": "dd.MM.y, HH'h'mm",
    "prices_indicative": "Les prix d’achat et de vente sont indicatifs.",
    "footnote_text": "En raison de la méthode de calcul utilisée, il se peut qu'il y ait certains sauts dans les chiffres-clés entre les dates de détachement et les dates de paiement du coupon.",
    "product_filter_info_no_match": "Malheureusement aucun produit ne correspond aux critères sélectionnés.",
    "subscription_no_products": "Aucun produit n'est actuellement en souscription. Nous vous invitons à regarder nos produits qui sont actuellement en marché secondaire.",
    "issued_no_products": "Aucun produit n'a été émis récemment. Nous vous invitons à regarder nos produits qui sont actuellement en marché secondaire.",
    "barrier_touched_notification": "La barrière a été touchée par {{touchedBarrierUnderlyingName}}, le {{firstTouchedBarrierDate}}.",
    "product_details": "Détails du produit",
    "phone_disclaimer": "*Les communications sur cette ligne sont enregistrées.",
    "phone_disclaimer_contact": "*Les communications sur ces lignes sont enregistrées.",
    "cookiesBannerDisclaimer": "En cliquant sur le bouton OK, vous acceptez l'utilisation de cookies de statistique ou de marketing sur notre site Internet. Vous trouverez de plus amples informations <a href='/cookies'>ici</a>.",
    "contact_form": "Formulaire de contact: produitsstructures.lukb.ch",
    "capLevel": "Cap",
    "product_report": "Rapport du produit",
    "hours": "",
    "tel": "Tél",
    "Hit": "Hit",
    "customDateTimePhpFormat": "d.m.Y, H\\hi",
    "BarrierReverseConvertibleName": "LUKB Barrier Reverse Convertible",
    "BarrierReverseConvertibleCategory": "Catégorie SSPA: 1230 - Optimisation de la performance",
    "BarrierReverseConvertibleDescription": "Les LUKB Barrier Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs à la barrière du sous-jacent. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau de la barrière. Ces produits ne prévoient pas de remboursement anticipé.",
    "MultiBarrierReverseConvertibleName": "LUKB Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibleCategory": "Catégorie SSPA: 1230 - Optimisation de la performance",
    "MultiBarrierReverseConvertibleDescription": "Les LUKB Multi Barrier Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs à la barrière des sous-jacents. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau de la barrière, appliqué sur le sous-jacent le moins performant. Ces produits ne prévoient pas de remboursement anticipé.",
    "AutocallableBarrierReverseConvertibleName": "LUKB Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleCategory": "Catégorie SSPA: 1230 - Optimisation de la performance",
    "AutocallableBarrierReverseConvertibleDescription": "Les LUKB Autocallable Barrier Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs à la barrière du sous-jacent. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau de la barrière. Ces produits peuvent faire l’objet d’un remboursement anticipé à hauteur de 100.00% du nominal, en fonction du niveau du sous-jacent à l\\'une des dates d’observation de remboursement anticipé.",
    "AutocallableMultiBarrierReverseConvertibleName": "LUKB Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleCategory": "Catégorie SSPA: 1230 - Optimisation de la performance",
    "AutocallableMultiBarrierReverseConvertibleDescription": "Les LUKB Autocallable Multi Barrier Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs à la barrière des sous-jacents. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau de la barrière, appliqué sur le sous-jacent le moins performant. Ces produits peuvent faire l’objet d’un remboursement anticipé à hauteur de 100.00% du nominal, en fonction du niveau du sous-jacent le moins performant à l\\'une des dates d’observation de remboursement anticipé.",
    "SoftCallableBarrierReverseConvertibleName": "LUKB Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibleCategory": "Catégorie SSPA: 1230 - Optimisation de la performance",
    "SoftCallableBarrierReverseConvertibleDescription": "Les LUKB Softcallable Barrier Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs à la barrière du sous-jacent. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau de la barrière. L’émetteur a le droit de rappeler tous les produits pour un remboursement anticipé à hauteur de 100.00% du nominal.",
    "SoftCallableMultiBarrierReverseConvertibleName": "LUKB Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibleCategory": "Catégorie SSPA: 1230 - Optimisation de la performance",
    "SoftCallableMultiBarrierReverseConvertibleDescription": "Les LUKB Softcallable Multi Barrier Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs à la barrière des sous-jacents. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau de la barrière, appliqué sur le sous-jacent le moins performant. L’émetteur a le droit de rappeler tous les produits pour un remboursement anticipé à hauteur de 100.00% du nominal.",
    "ReverseConvertibleName": "LUKB Reverse Convertible",
    "ReverseConvertibleCategory": "Catégorie SSPA: 1220 - Optimisation de la performance",
    "ReverseConvertibleDescription": "Les LUKB Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs au prix d\\'exercice du sous-jacent. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau du prix d’exercice. Ces produits ne prévoient pas de remboursement anticipé.",
    "MultiReverseConvertibleName": "LUKB Multi Reverse Convertible",
    "MultiReverseConvertibleCategory": "Catégorie SSPA: 1220 - Optimisation de la performance",
    "MultiReverseConvertibleDescription": "Les LUKB Multi Reverse Convertibles permettent aux investisseurs de profiter des mouvements latéraux ou supérieurs au prix d\\'exercice des sous-jacents. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu\\'au niveau du prix d’exercice, appliqué sur le sous-jacent le moins performant. Ces produits ne prévoient pas de remboursement anticipé.",
    "AutocallableMultiReverseConvertibleName": "LUKB Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleCategory": "Catégorie SSPA: 1220 - Optimisation de la performance",
    "AutocallableMultiReverseConvertibleDescription": "Les LUKB Autocallable Multi Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs au prix d\\'exercice des sous-jacents. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau du prix d’exercice, appliqué sur le sous-jacent le moins performant. Ces produits peuvent faire l’objet d’un remboursement anticipé à hauteur de 100.00% du nominal, en fonction du niveau du sous-jacent le moins performant à l\\'une des dates d’observation de remboursement anticipé.",
    "SoftCallableReverseConvertibleName": "LUKB Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibleCategory": "Catégorie SSPA: 1220 - Optimisation de la performance",
    "SoftCallableReverseConvertibleDescription": "Les LUKB Softcallable Reverse Convertibles permettent aux investisseurs de profiter de mouvements latéraux ou supérieurs au prix d\\'exercice du sous-jacent. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu’au niveau du prix d’exercice. L’émetteur a le droit de rappeler tous les produits pour un remboursement anticipé à hauteur de 100.00% du nominal.",
    "SoftCallableMultiReverseConvertibleName": "LUKB Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibleCategory": "Catégorie SSPA: 1220 - Optimisation de la performance",
    "SoftCallableMultiReverseConvertibleDescription": "Les LUKB Softcallable Multi Reverse Convertibles permettent aux investisseurs de profiter des mouvements latéraux ou supérieurs au prix d\\'exercice des sous-jacents. Ces produits rapportent un coupon garanti et offrent une marge de sécurité jusqu\\'au niveau du prix d’exercice, appliqué sur le sous-jacent le moins performant. L’émetteur a le droit de rappeler tous les produits pour un remboursement anticipé à hauteur de 100.00% du nominal.",
    "CapitalProtectionWithParticipationName": "LUKB Certificats de Protection du Capital avec Participation",
    "CapitalProtectionWithParticipationCategory": "Catégorie SSPA: 1100 - Protection du capital",
    "CapitalProtectionWithParticipationDescription": "Les LUKB Certificats de Protection du Capital avec Participation permettent aux investisseurs de profiter de la hausse du sous-jacent et d’une protection partielle du capital à l’échéance. A la date de remboursement, ces produits remboursent un montant minimum égal au niveau de protection du capital, plus une participation non plafonnée sur la performance du sous-jacent au-delà du prix d’exercice.",
    "CapitalProtectionWithParticipationWorstOfName": "LUKB Certificats de Protection du Capital avec Participation sur le worst of",
    "CapitalProtectionWithParticipationWorstOfCategory": "Catégorie SSPA: 1100 - Protection du capital",
    "CapitalProtectionWithParticipationWorstOfDescription": "Les LUKB Certificats de Protection du Capital avec Participation sur le worst of permettent aux investisseurs de profiter de la hausse des sous-jacents, et d’une protection partielle du capital à l’échéance. A la date de remboursement, ces produits remboursent un montant minimum égal au niveau de protection du capital, plus une participation non plafonnée sur la performance du sous-jacent le moins performant au-delà de son prix d’exercice.",
    "CapitalProtectionWithParticipationCappedWorstOf": "Note à Capital Protégé avec Participation plafonnée sur le worst of",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf": "Note à Capital Protégé avec Coupon Conditionnel Mémoire",
    "range_0": "< 10.00%",
    "range_1": "10.00% - 20.00%",
    "range_2": "20.00% - 30.00%",
    "range_3": "30.00% - 40.00%",
    "range_4": "40.00% - 50.00%",
    "range_5": "> 50.00%",
    "php_date_iso8601_format": "Y-m-d\\TH:i:sP",
    "pdf_report_disclaimer": "Les indications qui sont données ici ont un caractère purement indicatif et ne constituent pas une offre. Malgré une vérification minutieuse du contenu, la Banque cantonale de Lucerne n'assume aucune garantie ni responsabilité quant à leur exactitude.",
    "once_after": "uniquement après",
    "observationDaysList": "Dates d'observation de coupon conditionnel",
    "conditionalCouponPa": "Coupon Conditionnel p.a.",
    "triggerObservationEndDate": "Date d'observation de coupon conditionnel",
    "triggerLevelRefCurPercent": "Seuil de paiement de coupon",
    "paymentDate": "Dates de paiement de coupon conditionnel",
    "paymentAmount": "Montants de coupon conditionnel",
    "paidAmount": "",
    "paid": "Payé",
    "paid_not": "Non payé",
    "maturity": "A maturité",
    "key_information_document": "KID",
    "kid_dl": "Télécharger le KID",
    "dividendHandling": "Traitement des dividendes",
    "Participation": "Participation",
    "distributing": "Compensation en espèces",
    "reinvesting": "Réinvestis",
    "discounting": "Escomptés",
    "ActivelyManagedTrackerCertificate": "Certificat Tracker Activement Géré",
    "ActivelyManagedTrackerCertificateName": "LUKB Certificat Tracker Activement Géré",
    "ActivelyManagedTrackerCertificateCategory": "Catégorie SSPA: 1300 - Participation",
    "basketComponent": "Sous-jacent",
    "weight": "Pondération initiale",
    "basketCompPerf2RefPriceRelative": "Performance depuis l'émission",
    "coupon_has_not_triggered": "Coupon non déclenché",
    "coupon_has_triggered": "Coupon déclenché",
    "lifespan_text_callable": "Durée max.",
    "lifespan_text_non_callable": "Durée",
    "newsletter_title": "Abonner la Newsletter",
    "newsletter_subtitle": "Souscrivez à notre Newsletter hebdomadaire et à notre idée d'investissement du mois et restez informés.",
    "newsletter_please_choose_type": "Sélectionnez la Newsletter que vous désirez souscrire:",
    "newsletter_example": "exemple",
    "newsletter_products_in_subscription": "Produits en souscription (hebdomadaire)",
    "newsletter_products_in_subscription_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_FR.html",
    "newsletter_secondary_market_opportunities": "Opportunités sur le marché secondaire (hebdomadaire)",
    "newsletter_secondary_market_opportunities_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_SEK_FR.html",
    "newsletter_form_field_gotoUrl": "https://produitsstructures.lukb.ch/services/newsletter-authentication",
    "newsletter_form_field_gotoConfirmationUrl": "https://produitsstructures.lukb.ch/services/newsletter-confirmation",
    "newsletter_investment_ideas": "Idée d'investissement mensuelle",
    "newsletter_languages": "Langue de la Newsletter",
    "newsletter_languages_DE": "Allemand",
    "newsletter_languages_EN": "Anglais",
    "newsletter_languages_IT": "Italien",
    "newsletter_languages_FR": "Français",
    "newsletter_please_choose_contact": "Merci d'entrer vous détails de contact ici:",
    "newsletter_contact_title": "Titre*",
    "newsletter_contact_mr": "Monsieur",
    "newsletter_contact_mrs": "Madame",
    "newsletter_contact_firstname": "Prénom*",
    "newsletter_contact_lastname": "Nom*",
    "newsletter_contact_company": "Société",
    "newsletter_contact_company_name": "Société",
    "newsletter_contact_email": "E-Mail*",
    "newsletter_privacy": "Je confirme que j'accepte les <a target=\"_blank\" href=\"/mentions-legales#conditions-d-utilisation-g-n-rales-copyright\">conditions d'utilisation</a> et la <a target=\"_blank\" href=\"/mentions-legales#protection-et-s-curit-des-donn-es\">politique de confidentialité</a>.",
    "newsletter_required": "Tous les champs marqués d'un * sont obligatoires. La transmission de vos données est cryptée.",
    "newsletter_submit": "Abonner",
    "newsletter_error_type": "Merci de sélectionner au moins une Newsletter",
    "newsletter_error_salutation": "Prière de sélectionner une formule d'appel.",
    "newsletter_error_firstname": "Prière de saisir votre prénom.",
    "newsletter_error_lastname": "Prière de saisir votre nom.",
    "newsletter_error_email": "Prière de saisir une adresse e-mail valide.",
    "newsletter_error_privacy": "Merci de confirmer les conditions de protection des données",
    "newsletter_form_field_gotoErrorUrl": "https://produitsstructures.lukb.ch/services/mail-error",
    "quoting_method_value": "en unités",
    "basket": "Basket",
    "am": "le",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponName": "LUKB Autocallable Multi Barrier Reverse Convertible avec Coupon Conditionnel Mémoire",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponCategory": "Catégorie SSPA: 1260 - Optimisation de rendement",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfName": "LUKB Note à Capital Protégé avec Coupon Conditionnel Mémoire",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfCategory": "Catégorie SSPA: 1140 - Protection du Capital",
    "CapitalProtectionWithConditionalCouponWorstOfName": "LUKB Note à Capital Protégé avec Coupon Conditionnel",
    "CapitalProtectionWithConditionalCouponWorstOfCategory": "Catégorie SSPA: 1140 - Protection du Capital",
    "CapitalProtectionWithParticipationCappedName": "LUKB Note à Capital Protégé avec Participation plafonnée",
    "CapitalProtectionWithParticipationCappedCategory": "Catégorie SSPA: 1100 - Protection du Capital",
    "CapitalProtectionWithParticipationCappedWorstOfName": "LUKB Note à Capital Protégé avec Participation plafonnée sur le worst of",
    "CapitalProtectionWithParticipationCappedWorstOfCategory": "Catégorie SSPA: 1100 - Protection du Capital",
    "CapitalProtectionWithParticipationSumBasketName": "LUKB Note à Capital Protégé avec Participation sur le Basket",
    "CapitalProtectionWithParticipationSumBasketCategory": "Catégorie SSPA: 1100 - Protection du Capital",
    "CapitalProtectionWithParticipationCappedSumBasketName": "LUKB Note à Capital Protégé avec Participation plafonnée sur le Basket",
    "CapitalProtectionWithParticipationCappedSumBasketCategory": "Catégorie SSPA: 1100 - Protection du Capital",
    "Payment_date_dividend": "Date de paiement",
    "absolute_payment": "Montant du paiement de la compensation",
    "dividends": "Paiement de la compensation",
    "performanceSinceIssuePercentWithoutDividends": "Evolution du cours depuis l'émission",
    "DualCurrencyNote": "Dual Currency Note",
    "strikeLevelAndMaxReturn": "Niveau de Cap et Rendement maximal",
    "maxReturn": "max. Rendite",
    "investmentCurrency": "Devise d'Investissement",
    "alternativeCurrency": "Devise alternative",
    "cashorphysicalForDualCurrencyNote": "En espèce (das la devise d'investissement) ou converti dans la devise alternative",
    "physicalForDualCurrencyNote": "Converti dans la devise alternative",
    "chart_disclaimer_DualCurrencyNote": "La performance du produit, le rendement max. jusqu'à l'échéance et le rendement max. p.a. sont basés sur les cours actuels.<br/>La performance du sous-jacent et le cours du sous-jacent sont basés sur le cours de clôture du jour précédent."
};