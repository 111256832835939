// productList.recentlyIssuedProducts.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ProductListRecentlyIssuedProductsController', ProductListRecentlyIssuedProductsController);

    // Inject dependencies.
    ProductListRecentlyIssuedProductsController.$inject = [
        '$scope',
        '$rootScope',
        'InstrumentService',
        'ProfilerService',
        'TableConfigService',
        'MediaQueryService',
        '$timeout',
        'HistoryService'
    ];

    function ProductListRecentlyIssuedProductsController(
        vm,
        $rootScope,
        InstrumentService,
        ProfilerService,
        TableConfigService,
        MediaQueryService,
        $timeout,
        HistoryService
    ) {
        var container = '.recently-issued-products',
            url = '/recently-issued-products';

        vm.media = MediaQueryService.getMedia();

        // Update tabs navigation when enter controller
        $timeout(vm.$parent.updateNavigation);

        // Get groups.
        $(container).addClass('icon-loading');

        if (HistoryService.isCurrentlyAllowedToRestore && HistoryService.restoreData()) {
            handleSuccessResponse(HistoryService.restoreData(), 'ignore');
            handleFinallyCallback();
        } else {
            InstrumentService.getGroups(url).then(handleSuccessResponse, function() {
                vm.groups = [];
            }).finally(handleFinallyCallback);
        }

        function handleSuccessResponse(data, ignore) {

            // Store data for back button cache
            if (ignore !== 'ignore') {
                HistoryService.storeData(data);
            }

            vm.groups = data.groups;

            // Get data for textfield
            vm.cmsText = data.text;

            // Start restoring previous navigation state
            HistoryService.restore(vm.groups);
        }

        function handleFinallyCallback() {
            $timeout(function() {
                ProfilerService.setTimeLoad();
                if (vm.groups.length) {
                    vm.showGroupNavigation = true;
                }

                $(container).removeClass('icon-loading');

                //Show label prices indicative
                $('.label-prices-indicative').show();

                // Force recalculation of mobile instrument table cells heights
                if (vm.media === 'mobile' || vm.media === 'medium') {
                    $rootScope.$broadcast('TriggerCalculateCells', '');
                }
            }, 100);
        }

        /**
         * Assign table template to instrument groups
         * @param {string} groupId - Instrument group id.
         */
        vm.assignTableTemplate = function(groupId) {
            return TableConfigService.get(groupId);
        };

        // Get instrument documents
        vm.getDocuments = function(event, instrument) {
            event.stopPropagation();

            var container = event.target;

            //Set flyout top position
            function setPosition() {
                $('flyout-documents').css('top', $(event.target).position().top + 40);
                vm.showFlyOutDocuments = 'downloads';
                vm.flyOutDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link in flyout
                vm.flyOutDocumentsValor = instrument.valor.value; // Set valor for product flyout
            }

            // Select flyout or modal regarding the current mediaquery
            function showFlyout() {

                // Set button active state
                $(container).addClass('active');

                // Choose flyout or modalbox for mobile devices
                if (vm.media === 'mobile' || vm.media === 'medium') {
                    vm.showModal = true;
                    vm.modalDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link
                } else {
                    setPosition();
                }
            }

            //Show flyout if document data is already added
            if (instrument.documents) {
                showFlyout();
                return;
            }

            // Get data using service
            $(container).addClass('loading');
            InstrumentService.getDocuments(instrument.sin.value).then(function(data) {
                vm.flyoutInstrument = data;
            }).finally(function() {
                if (!instrument.isKidAvailable.value) {
                    vm.flyoutInstrument.kid = null;
                }

                showFlyout();
                $(container).removeClass('loading');
            });
        };

        /**
         * Sort group by calling backend api (to be called from instrument-table directive)
         */
        vm.sortGroup = function() {

            // Paging not supported here

        };

        /**
         * Apply local instrument table sort to all groups
         * It will be called from the instrumentTable directive
         * @param sortKey
         * @param direction
         */
        vm.applySortForAllGroups = function(sortKey, direction) {
            try {
                if (vm.groups && vm.groups.length && vm.groups.length > 0) {
                    for (var i = 0; i < vm.groups.length; i++) {
                        vm.groups[i].sorting = {};
                        vm.groups[i].sorting[sortKey] = direction;
                        vm.groups[i].sortingKey = sortKey;
                        vm.groups[i].sortingIndex = direction;
                    }
                }

                vm.$apply();

            } catch (e) {
                console.log(e);
            }
        };
    }
})();
