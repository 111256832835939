// productList.findProducts.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ProductListYieldMatrixController', ProductListYieldMatrixController);

    // Inject dependencies.
    ProductListYieldMatrixController.$inject = [
        '$scope',
        'ProductFilterService',
        'TableConfigService',
        'InstrumentService',
        '$location',
        'ProfilerService',
        'MediaQueryService',
        'PAGING',
        '$timeout',
        'YIELD_MATRIX_DEFAULT_DATA',
        'HistoryService',
        '$q',
        '$rootScope'
    ];

    function ProductListYieldMatrixController(
        vm,
        ProductFilterService,
        TableConfigService,
        InstrumentService,
        $location,
        ProfilerService,
        MediaQueryService,
        PAGING,
        $timeout,
        YIELD_MATRIX_DEFAULT_DATA,
        HistoryService,
        $q,
        $rootScope
    ) {
        var container = '.yield-matrix',
            url = '/yield-matrix',
            query = $location.search().search,
            filterValues = {};

        vm.container = $(container);

        // Initial tab 
        vm.activeTab = {
            name: 'BarrierReverseConvertible',
            index: 0
        };

        // To render the filter before data is loaded from API
        vm.filterData = YIELD_MATRIX_DEFAULT_DATA;

        // Update tabs navigation when enter controller 
        $timeout(vm.$parent.updateNavigation);

        vm.instrumentCountToShowPaging = 5; // How many instruments to show "button all"
        vm.media = MediaQueryService.getMedia();
        vm.filterType = 'yieldMatrix'; // Choose filter handling "filter" or "yieldMatrix"


        /**
         *  Parse url params to filter object
         *  If doesn't exist clear filter search to prevent caching old filterdata
         */
        if (query) {
            ProductFilterService.reset();
            filterValues.filter = window.getParamsFromUrl(query);
        } else {
            filterValues = {};
            ProductFilterService.reset();
        }

        /**
         * Assign table template to instrument groups
         * @param {string} groupId - Instrument group id.
         */
        vm.assignTableTemplate = function(groupId) {
            return TableConfigService.get(groupId);
        };

        /**
         * Change tab.
         * @param {int} index - Index of selected tab.
         */
        vm.changeTab = function(index) {
            vm.activeTab.index = index;
            vm.activeTab.name = Object.keys(vm.tabs)[index];

            // Force recalculation of mobile instrument table cells heights
            if (vm.media === 'mobile' || vm.media === 'medium') {
                $rootScope.$broadcast('TriggerCalculateCells', '');
            }
        };


        /**
         * Get Instrumentgroup list data
         * @param {object} group - Instrumentgroup.
         */
        function getInstrumentGroupListData(group, merge) {
            var additionalParams,
                deferred = $q.defer();

            // Get products
            $(container + ' [data-id]').addClass('icon-loading');

            // Add additional params for list query (sorting)
            if (group.sorting) {
                additionalParams = {
                    sort: group.sorting
                };
            }

            // Get instruments
            InstrumentService.getProducts(
                group,
                url,
                ProductFilterService.getListQueryUrl(additionalParams)
            ).then(function(data) {

                handleSuccess(data, merge);

            }, function(err) {
                console.log(err);
                deferred.reject();
            }).finally(function() {
                deferred.resolve();

                // Remove loader.
                $(container + ' [data-id]').removeClass('icon-loading');
            });

            function handleSuccess(data, merge) {

                //Merge old with new table data
                if (merge) {
                    Array.prototype.push.apply(group.rows, data.rows);
                } else { //Overwrite
                    angular.extend(group, data);
                }
            }

            return deferred.promise;
        }


        /**
         * Increase quantity of rows to default paging
         * @param {object} group - Instrumentgroup.
         */
        vm.showAll = function(group) {
            var dfd = $q.defer();

            // Init paging
            if (!group.paging) {
                var paging = {
                    page: 1,
                    rowsPerPage: PAGING.rowsPerPage
                };

                // Set instrumnent count
                paging.count = group.count;

                // Calculate pages
                paging.pages = Math.ceil(paging.count / PAGING.rowsPerPage);

                // Set intital paging object
                group.paging = paging;
            }

            // Enable paging, also removes "button all"
            group.pagingEnabled = true;

            // Update only one group
            getInstrumentGroupListData(group).then(function() {
                dfd.resolve();
            });
            return dfd.promise;

        };


        /**
         * Update paging of specific instrumentgroup
         * ProductFilterService.groups already updated by pagingDirective (group.paging)   
         */
        vm.updatePaging = function(data, merge) {
            var deferred = $q.defer();
            getInstrumentGroupListData(data, merge).finally(function() {
                deferred.resolve();
            });

            return deferred.promise;
        };

        /**
         * Set query data defaults for filter
         * @param {string} filterType - "filter" or "yieldMatrix"
         */
        ProductFilterService.setQueryData(vm.filterType);


        /**
         * Initially submit filter to get instrument data
         * @param {function} ProductFilterService returns query URL
         */
        $(container).addClass('icon-loading');

        if (HistoryService.isCurrentlyAllowedToRestore && HistoryService.restoreData()) {
            handleSuccessResponse(HistoryService.restoreData(), 'ignore');
            handleFinallyCallback();
        } else {
            ProductFilterService.submit(
                url,
                ProductFilterService.getQueryURL(filterValues.filter)
            ).then(handleSuccessResponse, function() {
                console.log('Error: Submit Filter');

            }).finally(handleFinallyCallback);
        }

        function handleSuccessResponse(data, ignore) {

            // Store data for back button cache
            if (ignore !== 'ignore') {
                HistoryService.storeData(data);
            }

            // Set filter data
            vm.filterData = data;

            // Get data for textfield
            vm.cmsText = data.text;

            if (!data.groups.length) {
                return;
            }

            // Set list data
            vm.tabs = {
                BarrierReverseConvertible: data.groups.searchObjectsByKey('name', 'BarrierReverseConvertible')[0],
                MultiBarrierReverseConvertible: data.groups.searchObjectsByKey('name', 'MultiBarrierReverseConvertible')[0],
                Autocallable_SoftCallable_MultiBarrierReverseConvertible: data.groups.searchObjectsByKey('name', 'Autocallable_SoftCallable_MultiBarrierReverseConvertible')[0]
            };

            // Initially activate tab 1
            $timeout(function() {
                $(container).find('tab[index="0"]').show();
            });

            // Start restoring previous navigation state
            HistoryService.restore(vm.tabs, vm.activeTab, vm);

        }

        function handleFinallyCallback() {
            $timeout(function() {
                ProfilerService.setTimeLoad();
                vm.showGroupNavigation = true;
                $(container).removeClass('icon-loading');
            }, 50);
        }

        // Get instrument documents
        vm.getDocuments = function(event, instrument) {
            event.stopPropagation();

            var container = event.target;

            //Set flyout top position
            function setPosition() {
                $('flyout-documents').css('top', $(event.target).position().top + $(event.target).closest('section').position().top + 40);
                vm.showFlyOutDocuments = 'downloads';
                vm.flyOutDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link in flyout
                vm.flyOutDocumentsValor = instrument.valor.value; // Set valor for product flyout
            }

            // Select flyout or modal regarding the current mediaquery
            function showFlyout() {

                // Set button active state
                $(container).addClass('active');

                // Choose flyout or modalbox for mobile devices
                if (vm.media === 'mobile' || vm.media === 'medium') {
                    vm.showModal = true;
                    vm.modalDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link
                } else {
                    setPosition();
                }
            }

            //Show flyout if document data is already added
            if (instrument.documents) {
                showFlyout();
                return;
            }

            // Get data using service
            $(container).addClass('loading');
            InstrumentService.getDocuments(instrument.sin.value).then(function(data) {
                vm.flyoutInstrument = data;
            }).finally(function() {
                if (!instrument.isKidAvailable.value) {
                    vm.flyoutInstrument.kid = null;
                }

                showFlyout();
                $(container).removeClass('loading');
            });
        };

        /**
         * Sort group by calling backend api
         * @param group
         */
        vm.sortGroup = function(group) {

            if (vm.activeTab) {

                // Check if paging already enabled or not
                if (!group.pagingEnabled && group.paging) {
                    group.paging.rowsPerPage = 20;
                }

                getInstrumentGroupListData(group);
            }
        };

        /**
         * Sort group by calling backend api
         * @param group
         */
        vm.sortGroupMobile = function(group) {

            if (vm.activeTab) {

                // Check if paging already enabled or not
                if (!group.pagingEnabled && group.paging) {
                    group.paging.rowsPerPage = 20;
                }

                // Force going to 1st page while sorting in mobile
                group.paging.page = 1;

                getInstrumentGroupListData(group);
            }
        };

        /**
         * Apply local instrument table sort to all groups
         * It will be called from the instrumentTable directive
         * @param sortKey
         * @param direction
         */
        vm.applySortForAllGroups = function(sortKey, direction) {
            try {
                if (vm.tabs) {
                    angular.forEach(vm.tabs, function(tab) {
                        try {
                            if (tab.groups && tab.groups.length && tab.groups.length > 0) {
                                for (var i = 0; i < tab.groups.length; i++) {
                                    tab.groups[i].sorting = {};
                                    tab.groups[i].sorting[sortKey] = direction;
                                    tab.groups[i].sortingKey = sortKey;
                                    tab.groups[i].sortingIndex = direction;
                                }
                            }
                        } catch (e) {
                            console.log(e);
                        }
                    });
                    vm.$apply();
                }

            } catch (e) {
                console.log(e);
            }
        };
    }
})();